<template>
    <div class="Alistofitems">
        <el-table 
            :data="tableData.slice((currentPage-1)*pageSize,currentPage*pageSize)"
            style="width: 100%;height:97%"
            stripe
            :cell-style="{'text-align':'center'}"
            :header-cell-style="{background:'#f2f2f2',color:'#606266','text-align':'center'}"
        >   
            <el-table-column type="index" width="100" label="#"></el-table-column>
            <el-table-column prop="iname" label="项目名称"></el-table-column>
            <el-table-column prop="name" label="用途"></el-table-column>
            <el-table-column prop="money" label="金额(元)"></el-table-column>
            <el-table-column prop="bill" label="票据">
                <template slot-scope="scope">
                    <a :href="scope.row.bill" target="_blank" rel="noopener noreferrer">
                    <img :src="scope.row.bill" alt="" width="100" height="80">
                    </a>
                </template>
            </el-table-column>
            <el-table-column prop="time" label="状态">
                <template slot-scope="scope">
                    <span>{{scope.row.state==1?'待审核':scope.row.state==3?'已通过':scope.row.state==4?'已驳回':''}}</span>
                    <!-- <el-progress 
                        :percentage="scope.row.rogress" 
                        :color="scope.row.rogress==100?'#67c23a':''"
                        :stroke-width="8"
                    ></el-progress> -->
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button @click="agree(scope.row)" type="text">通过</el-button>
                    <el-button @click="dialogVisibleForm=true,reject(scope.row)" type="text">驳回</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-row>
            <el-col :span="24">
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-sizes="[10, 15, 20]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="tableData.length">
                    </el-pagination>
                </div>
            </el-col>
        </el-row>
        <div class="tcation_Popup">
            <el-dialog
                title="驳回原因"
                :visible.sync="dialogVisibleForm"
                width="650px"
                :before-close="handleClose"
                :show-close="false">
                <i class="el-icon-close closei" @click="closei('Form')"></i>
                <el-form :model="Form" :rules="rules" ref="Form" label-width="100px" class="demo-ruleForm">
                    <el-form-item prop="cause" label="驳回原因" style="width:100%">
                        <el-input type="textarea" :rows="6" v-model="Form.cause"></el-input>
                    </el-form-item>
                </el-form>
                <span class="dialog-footer" slot="footer">
                    <el-button size="small" type="primary" @click="addmbers('Form')">确 定</el-button>
                    <el-button size="small" @click="closei('Form')">取 消</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            dialogVisibleForm:false,
            Form:{
                cause:'',
            },

            rules:{
                cause:[
                    { required: true, message: '请输入驳回原因', trigger: 'blur' }
                ]
            },
            pageSize:10,
            totalnum:0,
            currentPage:1,
            tableData:[],
        }
    },
    methods: {
        getapp(){
            this.axios.yexpendlist({
                params:{
                    uid:this.$store.state.uid
                }
            }).then(res=>{
                if(res.data.code==200){
                    this.tableData = res.data.rows
                }else{
                    this.tableData = []
                }
            }).catch(err=>{
                
            })
        },
        reject(rows){
            this.Form.expId = rows.expId
        },
        agree(rows){
            this.axios.expenditureupdate({
                expId:rows.expId,
                state:5
            }).then(res=>{
                if(res.data.code==200){
                    this.getapp()
                }
            }).catch(err=>{

            })
        },
        //驳回
        addmbers(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.Form.state = 4
                    this.axios.expenditureupdate(
                        this.Form
                    ).then(res=>{
                        if(res.data.code==200){
                            this.dialogVisibleForm = false
                            this.getapp()
                            this.$refs[formName].resetFields();
                        }
                    }).catch(err=>{

                    })
                }else{

                }
            })
        },
        handleClose(done){

        },
        handleSizeChange(val){
            this.pageSize = val;
        },
        handleCurrentChange(val){
            this.currentPage = val;
        },
    },
    mounted() {
        this.getapp()
    },
}
</script>
<style scoped>
@import './../../assets/css/admin/Alistofitems.css';
</style>
<style>
.tcation_Popup .el-dialog__header{
    padding: 0 0 0 20px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    border-bottom: 1px solid #eaecee;
    background: #409EFF;
}
.tcation_Popup .el-dialog__title{
    font-size: 14px;
    color: #fff;
}
.tcation_Popup .el-table__body-wrapper{
    min-height: 300px!important;;
    height: 300px!important;
}
.tcation_Popup .el-table__empty-block{
    min-height: 300px!important;;
    height: 300px!important;
}
.tcation_Popup .el-dialog__footer{
    text-align: center;
}
.tcation_Popup .el-textarea__inner{
    resize: none;
    width: 450px;
}
.Alistofitems .el-table__body-wrapper{
    min-height: calc(100vh - 314px);
}
.Alistofitems .el-table__empty-block{
    min-height: calc(100vh - 314px);
}
</style>